import React from 'react';
import './pitch.css';

const Pitch = () => {
  return (
    <div className="pitch-container">
      <div className="pitch-card">
        <div className="soccer-ball">⚽</div>
        <h3>Elite Training Focus</h3>
        <p>Our primary goal is to give players the advanced tools and training they need to excel in their game</p>
      </div>
      <div className="pitch-card">
        <div className="soccer-ball">⚽</div>
        <h3>Player-Centered Approach</h3>
        <p>We help each athlete unlock their full potential by focusing on individual strengths and development through goal setting</p>
      </div>
      <div className="pitch-card">
        <div className="soccer-ball">⚽</div>
        <h3>Mentorship-Inspired Growth</h3>
        <p>We help athletes develop the mindset and discipline needed to succeed in competitive environments</p>
      </div>
      <div className="pitch-card">
        <div className="soccer-ball">⚽</div>
        <h3>Local Expertise</h3>
        <p className='enfin'>With trainers who have competed at the college level, we offer an insiders perspective on what it takes to succeed</p>
      </div>
    </div>
  );
};

export default Pitch;
