import React from 'react';
import './CenteredDiv.css'; // Ensure you have the CSS for styling
import cam from './cam4.png'; // Assuming cam.png is in the same directory

const CenteredDiv = () => {
  return (
    <div className="centered-container">
      <img src={cam} alt="Camera" className="centered-image" />
      <div className="centered-text">Beyond the field, we provide structured plans tailored to each athlete’s needs, helping them continue their development on their own time.</div>
    </div>
  );
};

export default CenteredDiv;
