import React from 'react';
import './types.css';
import './index.css';

import './ImageLoop.css'; // Ensure you have the CSS for styling
import ones from './ones2.png';  // Ensure ones.png is in the src directory
import group from './group2.png';  // Ensure group.png is in the src directory

const Types = () => {
  return (
    <div className="types-container">
      <div className="text-rectangle50">Personalized College-Level Training Sessions</div> {/* Grey rectangle */}
      
      <div className="types-description">
        <p className="types-description2">
          Every player in each age group has unique strengths and areas for improvement. We customize each training session to target specific goals, ensuring maximum growth. We bring the quality, structure, and focus of collegiate soccer to every session. Our training is designed to sharpen technical skills, tactical awareness, and physical performance. We offer training in a few formats
        </p>
      </div>

      <div className="types-list">
        <div className="training-box">
          <img src={ones} alt="Private Training" className="training-image" /> {/* Image for Private Training */}
          <h3>Private Training (1:1)</h3>
          <p>One-on-one personalized training to focus on individual player needs, improving specific skills.</p>
        </div>
        <div className="training-box">
          <img src={group} alt="Group Training" className="training-image" /> {/* Image for Small Group Training */}
          <h3>Small Group Training </h3>
          <p>Small group sessions designed to enhance skills while benefiting from group dynamics, with individual attention. (2:1, 3:1, 4:1, 5:1)</p>
        </div>
      </div>
    </div>
  );
};

export default Types;
