import React, { useState } from 'react';
import headshot from './head2.jpeg';
import headshot2 from './head1.jpeg';
import headshot3 from './headshot3.jpeg';
import './aboutUs.css';
import { Grid, Box, Typography, useMediaQuery, useTheme, Divider, Button } from '@mui/material';

const Coaches = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const CoachComponent = ({ name, title, bio, headshot, isMobile }) => {
        const [isExpanded, setIsExpanded] = useState(false);
        const [showImageOverlay, setShowImageOverlay] = useState(false);

        // Split bio into sentences and get first 4
        const sentences = bio.split('.').filter(s => s.trim().length > 0);
        const shortBio = sentences.slice(0, 3).join('.') + (sentences.length > 3 ? '.' : '');
        const fullBio = bio;

        return (
            <>
                {isMobile ? (
                    <Box display='flex' flexDirection='column' alignItems='flex-start' style={{ marginTop: '20px', marginLeft: '10px' }}>
                        <Box display='flex' flexDirection='row' alignItems='center' width='100%'>
                            <img
                                src={headshot}
                                alt='Headshot'
                                style={{
                                    width: '125px',
                                    height: '125px',
                                    borderRadius: '10%',
                                    marginRight: '10px',
                                    cursor: 'pointer'
                                }}
                                onClick={() => setShowImageOverlay(true)}
                            />
                            <Box display='flex' flexDirection='column'>
                                <Typography style={{ fontWeight: 'bold' }} variant='h6'>{name}</Typography>
                                <Typography variant='subtitle1'>{title}</Typography>
                            </Box>
                        </Box>
                        <Typography
                            variant='body2'
                            style={{ textAlign: 'left', marginTop: '10px', color: 'white' }}
                        >
                            {isExpanded ? fullBio : shortBio}
                        </Typography>
                        {sentences.length > 4 && (
                            <Button
                                onClick={() => setIsExpanded(!isExpanded)}
                                sx={{
                                    color: '#D1501F',
                                    textTransform: 'none',
                                    padding: 0,
                                    marginTop: '8px',
                                    '&:hover': { backgroundColor: 'transparent', textDecoration: 'underline' }
                                }}
                            >
                                {isExpanded ? 'Read Less' : 'Read More'}
                            </Button>
                        )}
                    </Box>
                ) : (
                    <Grid container spacing={2} alignItems='flex-start' sx={{ minWidth: 0 }}>
                        <Grid item xs={3} sx={{ minWidth: '350px' }}>
                            <img
                                src={headshot}
                                alt='Headshot'
                                style={{
                                    width: '100%',
                                    maxWidth: '350px',
                                    height: 'auto',
                                    aspectRatio: '1 / 1',
                                    borderRadius: '7.5%',
                                    display: 'block'
                                }}
                            />
                        </Grid>
                        <Grid item xs={9} container direction='column' sx={{ minWidth: 0 }}>
                            <Grid item style={{ marginTop: 0 }}>
                                <Typography style={{ fontSize: '40px', color: 'white', fontWeight: 'bold' }}>{name}</Typography>
                            </Grid>
                            <Grid item style={{ marginTop: '8px' }}>
                                <Typography style={{ fontSize: '25px', color: 'white' }}>{title}</Typography>
                            </Grid>
                            <Grid item style={{ marginTop: '30px' }}>
                                <Typography
                                    variant='body1'
                                    style={{ color: 'white', fontSize: '20px' }}
                                >
                                    {isExpanded ? fullBio : shortBio}
                                </Typography>
                                {sentences.length > 3 && (
                                    <Button
                                        onClick={() => setIsExpanded(!isExpanded)}
                                        sx={{
                                            color: '#D1501F',
                                            textTransform: 'none',
                                            padding: 0,
                                            marginTop: '8px',
                                            '&:hover': { backgroundColor: 'transparent', textDecoration: 'underline' }
                                        }}
                                    >
                                        {isExpanded ? 'Read Less' : 'Read More'}
                                    </Button>
                                )}
                            </Grid>
                        </Grid>
                    </Grid>
                )}
                {isMobile && showImageOverlay && (
                    <Box
                        sx={{
                            position: 'fixed',
                            top: 0,
                            left: 0,
                            width: '100vw',
                            height: '100vh',
                            backgroundColor: 'rgba(0, 0, 0, 0.8)',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            zIndex: 1000
                        }}
                        onClick={() => setShowImageOverlay(false)}
                    >
                        <img
                            src={headshot}
                            alt='Enlarged Headshot'
                            style={{
                                maxWidth: '90%',
                                maxHeight: '90%',
                                borderRadius: '10%'
                            }}
                        />
                    </Box>
                )}
            </>
        );
    };

    return (
        <div className='rest' style={{ padding: '1.25%', color: 'white' }}>
            <Box>
                {isMobile ? (
                    <Box display='flex' flexDirection='column' gap={1}>
                        <CoachComponent
                            name="Daniel Rychenkov"
                            title="Founder/Director"
                            bio="Daniel Rychenkov, a Wheaton native and founder of Wheaton Legacy Soccer Academy, brings over 18 years of experience as a player and coach. A three-year varsity standout at Wheaton Academy, Daniel helped his team earn multiple Conference, Regional, and Sectional Championships, along with a third-place state finish. He began his collegiate career at Hope College under legendary coach Dave Brandt, playing a key role in securing their first MIAA Conference Championship since 2013. Daniel later transferred to Wheaton College to complete his four-year collegiate journey. For the past three years, Daniel has coached youth players at Wheaton United Soccer Club, further fueling his passion for developing young talent. As Founder & Director of Wheaton Legacy Soccer Academy, Daniel applies his extensive soccer knowledge and leadership skills to inspire excellence both on and off the field."
                            headshot={headshot}
                            isMobile={true}
                        />
                        <Divider sx={{ borderColor: 'rgba(255, 255, 255, 0.3)', my: 1 }} />
                        <CoachComponent
                            name="Logan Finnegan"
                            title="Technical Director"
                            bio="Logan Finnegan, a Wheaton native, brings high-level soccer experience to Wheaton Legacy Soccer Academy as Technical Director. A standout at Wheaton Academy, Logan earned All-State honors, Conference MVP, and First Team All-Conference recognition, leading his team to Conference, Regional, and Sectional Championships and a third-place state finish. He continued his career at the NCAA Division I level, playing for both IUPUI and DePaul University. As a two-year Team Captain at IUPUI, Logan earned Horizon League All-Second Team (2023) and CSC Academic All-District honors (2022, 2023). At DePaul, he appeared in all 17 matches, scoring seven goals and providing one assist over 929 minutes of play. Logan also excelled in USL League Two, scoring over 15 goals across three seasons with clubs including Charlotte Eagles, Lionsbridge FC, and RiverLight FC, where he was named Player of the Year. His success led to professional stints with the Charleston Battery (USL Championship) and Charlotte Independence (USL League One), enhancing his skills and understanding of the game at higher competitive levels."
                            headshot={headshot2}
                            isMobile={true}
                        />
                        <Divider sx={{ borderColor: 'rgba(255, 255, 255, 0.3)', my: 1 }} />
                        <CoachComponent
                            name="Danny Wagner"
                            title="Foundational Director"
                            bio="Danny Wagner, a Wheaton native and Wheaton Warrenville South graduate, serves as the Foundational Director at Wheaton Legacy Soccer Academy. focusing on training youth and beginner players. With over a decade of soccer experience, Danny earned recognition as Defender of the Year and received the Eye of the Tiger Award. He has been coaching for three years while pursuing a teaching degree in Special Education, enhancing his ability to mentor young athletes. Danny’s passion for developing fundamental skills and building strong technical foundations makes him a key part of the academy’s mission."
                            headshot={headshot3}
                            isMobile={true}
                        />
                        <Divider sx={{ borderColor: 'rgba(255, 255, 255, 0.3)', my: 1 }} />
                    </Box>
                ) : (
                    <Box display='flex' flexDirection='column' gap={4}>
                        <CoachComponent
                            name="Daniel Rychenkov"
                            title="Founder/Director"
                            bio="Daniel Rychenkov, a Wheaton native and founder of Wheaton Legacy Soccer Academy, brings over 18 years of experience as a player and coach. A three-year varsity standout at Wheaton Academy, Daniel helped his team earn multiple Conference, Regional, and Sectional Championships, along with a third-place state finish. He began his collegiate career at Hope College under legendary coach Dave Brandt, playing a key role in securing their first MIAA Conference Championship since 2013. Daniel later transferred to Wheaton College to complete his four-year collegiate journey. For the past three years, Daniel has coached youth players at Wheaton United Soccer Club, further fueling his passion for developing young talent. As Founder & Director of Wheaton Legacy Soccer Academy, Daniel applies his extensive soccer knowledge and leadership skills to inspire excellence both on and off the field."
                            headshot={headshot}
                            isMobile={false}
                        />
                        <Divider sx={{ borderColor: 'rgba(255, 255, 255, 0.3)', my: 4 }} />
                        <CoachComponent
                            name="Logan Finnegan"
                            title="Technical Director"
                            bio="Logan Finnegan, a Wheaton native, brings high-level soccer experience to Wheaton Legacy Soccer Academy as Technical Director. A standout at Wheaton Academy, Logan earned All-State honors, Conference MVP, and First Team All-Conference recognition, leading his team to Conference, Regional, and Sectional Championships and a third-place state finish. He continued his career at the NCAA Division I level, playing for both IUPUI and DePaul University. As a two-year Team Captain at IUPUI, Logan earned Horizon League All-Second Team (2023) and CSC Academic All-District honors (2022, 2023). At DePaul, he appeared in all 17 matches, scoring seven goals and providing one assist over 929 minutes of play. Logan also excelled in USL League Two, scoring over 15 goals across three seasons with clubs including Charlotte Eagles, Lionsbridge FC, and RiverLight FC, where he was named Player of the Year. His success led to professional stints with the Charleston Battery (USL Championship) and Charlotte Independence (USL League One), enhancing his skills and understanding of the game at higher competitive levels."
                            headshot={headshot2}
                            isMobile={false}
                        />
                        <Divider sx={{ borderColor: 'rgba(255, 255, 255, 0.3)', my: 4 }} />
                        <CoachComponent
                            name="Danny Wagner"
                            title="Foundational Director"
                            bio="Danny Wagner, a Wheaton native and Wheaton Warrenville South graduate, serves as the Foundational Director at Wheaton Legacy Soccer Academy, focusing on training youth and beginner players. With over a decade of soccer experience, Danny earned recognition as Defender of the Year and received the Eye of the Tiger Award. He has been coaching for three years while pursuing a teaching degree in Special Education, enhancing his ability to mentor young athletes. Danny’s passion for developing fundamental skills and building strong technical foundations makes him a key part of the academy’s mission."
                            headshot={headshot3}
                            isMobile={false}
                        />
                        <Divider sx={{ borderColor: 'rgba(255, 255, 255, 0.3)', my: 4 }} />
                    </Box>
                )}
            </Box>
        </div>
    );
};

export default Coaches;