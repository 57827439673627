import logo from './logo.svg';
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './Navbar';
import './App.css';
import Form from './Form';
import AboutUs from './AboutUs';
import Training from './Training';
import Coaches from './Coaches';


function App() {
  return (
    <Router>
      <Navbar />
      <Routes>
      <Route path="/" element={<Training />} />
      <Route path="/register" element={<Form />} />
      <Route path="/aboutUs" element={<AboutUs />} />
      <Route path="/coaches" element={<Coaches />} />
      </Routes>
    </Router>

  );
}

export default App;
