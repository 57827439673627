import React from 'react';
import { Grid, Box, Typography, useMediaQuery, useTheme, Button } from '@mui/material';
import pic1 from './pic11.webp'; // Import the image
import pic2 from './pic2.png';
import pic3 from './pic33.png';
import './aboutUs.css';
import { useNavigate } from 'react-router-dom'; // Import useNavigate for navigation

const AboutUs = () => {
  const theme = useTheme(); // Get the current theme
  const isMobile = useMediaQuery(theme.breakpoints.down('sm')); // Check if the screen size is 'sm' or smaller (mobile)


  const navigate = useNavigate(); // Initialize navigate hook

  const meetCoaches = () => {
    window.scrollTo(0, 0); // Scroll to the very top-left of the page
    navigate('/coaches'); // Navigate to /register
  };

  return (
    <div className='rest' style={{ padding: '1.25%', color: 'white' }}>
      <Grid container spacing={0}>
        {/* Mobile: Text, then Image */}
        {isMobile ? (
          <>
            {/* First Row: Text, then Picture */}
            <Grid item xs={12}>
              <Box
                component="img"
                src={pic2} // Use the imported image
                alt="Image 1"
                sx={{ width: '100%', height: 'auto', borderRadius: '3%', marginBottom: '0px', marginTop: '.5%' }} // Make the image slightly rounded
              />
            </Grid>
            <Grid item xs={12}>
              <p className='titlePar'>Our Mission</p>
              <p className='regPar'>
                At Wheaton Legacy Soccer Academy, we specialize in delivering high-level training to help youth soccer players of all ages achieve their full potential. Through personalized training sessions, tailored training plans, and dedicated mentorship, we provide the tools, guidance, and support needed to develop and train athletes to elevate their game, compete at the next level, and turn their goals into reality. We are committed to raising the standard of youth soccer training in our community. Whether that is striving for a starting spot, preparing for college recruitment, or simply looking to improve and sharpen your overall game, we’re here to guide you every step of the way.
              </p>
            </Grid>


            {/* Second Row: Text, then Picture */}
            <Grid item xs={12}>
              <Box
                component="img"
                src={pic3} // Use the imported image
                alt="Image 1"
                sx={{ width: '100%', height: 'auto', borderRadius: '3%', marginBottom: '0px', marginTop: '3%' }} // Make the image slightly rounded
              />
            </Grid>
            <Grid item xs={12}>
              <p className='titlePar'>Our Story</p>
              <p className='regPar'>
                Wheaton Legacy Soccer Academy was founded from a lifelong love of the game and a passion for growing a community of high-level soccer players in our area. With over 18 years of experience playing at high levels—from competitive club soccer to college soccer— I discovered a new spark for the sport: coaching and guiding youth players of all ages to achieve their goals and reach their full potential. This passion grew into a vision: to create a program that offers personalized, high-level coaching but also provides each athlete with a personal coach, and a mentor who is deeply invested in their success. Our trainers are committed to helping every player achieve their goals and realize their full potential on and off the field.
              </p>
            </Grid>

            {/* Third Row: Text, then Picture */}
            <Grid item xs={12}>
              <Box
                component="img"
                src={pic1} // Use the imported image
                alt="Image 1"
                sx={{ width: '100%', height: 'auto', borderRadius: '3%', marginBottom: '0px', marginTop: '3%' }} // Make the image slightly rounded
              />
            </Grid>
            <Grid item xs={12}>
              <p className='titlePar'>Our Coaches</p>
              <p className='regPar'>
                Our trainers are local college athletes who know what it takes to compete at the highest levels of the sport. With years of experience playing and training in competitive environments, they bring expert knowledge, advanced techniques, and a competitive edge to each session. Beyond their skills, they are deeply rooted in each player’s success and offer key insight and encouragement to players working toward their goals.
              </p>
              <Button onClick={meetCoaches} style={{ marginLeft: '2%', marginBottom: '15px', marginTop: '20px', backgroundColor: '#D1501F', }} variant='contained'>Check Out Our Staff</Button>
            </Grid>
          </>
        ) : (
          // Desktop: Picture on Left, Text on Right
          <>
            {/* First Row: Picture on Left, Text on Right */}
            <Grid item xs={12} sm={6}>
              <Box
                component="img"
                src={pic2} // Use the imported image
                alt="Image 1"
                sx={{ width: '100%', height: 'auto', borderRadius: '3%', marginBottom: '50px', marginTop: '20px' }} // Make the image slightly rounded
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <p className='titlePar' style={{ marginLeft: '5%' }}> Our Mission</p>
              <p className='regPar' style={{ marginLeft: '5%', marginBottom: '50px' }}>
                At Wheaton Legacy Soccer Academy, we specialize in delivering high-level training to help youth soccer players of all ages achieve their full potential. Through personalized training sessions, tailored training plans, and dedicated mentorship, we provide the tools, guidance, and support needed to develop and train athletes to elevate their game, compete at the next level, and turn their goals into reality. We are committed to raising the standard of youth soccer training in our community. Whether that is striving for a starting spot, preparing for college recruitment, or simply looking to improve and sharpen your overall game, we’re here to guide you every step of the way.
              </p>
            </Grid>

            {/* Second Row: Text on Left, Picture on Right */}
            <Grid item xs={12} sm={6}>
              <p className='titlePar' style={{ marginRight: '5%' }}>Our Story</p>
              <p className='regPar' style={{ marginRight: '5%', marginBottom: '50px' }}>
                Wheaton Legacy Soccer Academy was founded from a lifelong love of the game and a passion for growing a community of high-level soccer players in our area. With over 18 years of experience playing at high levels—from competitive club soccer to college soccer— I discovered a new spark for the sport: coaching and guiding youth players of all ages to achieve their goals and reach their full potential. This passion grew into a vision: to create a program that offers personalized, high-level coaching but also provides each athlete with a personal coach, and a mentor who is deeply invested in their success. Our trainers are committed to helping every player achieve their goals and realize their full potential on and off the field.
              </p>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box
                component="img"
                src={pic3} // Use the imported image
                alt="Image 2"
                sx={{ width: '100%', height: 'auto', borderRadius: '3%', marginBottom: '50px' }} // Make the image slightly rounded
              />
            </Grid>

            {/* Third Row: Picture on Left, Text on Right */}
            <Grid item xs={12} sm={6}>
              <Box
                component="img"
                src={pic1} // Use the imported image
                alt="Image 3"
                sx={{ width: '100%', height: 'auto', borderRadius: '3%', marginBottom: '15px' }} // Make the image slightly rounded
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <p className='titlePar' style={{ marginLeft: '5%', marginBottom: '15px' }}>Our Coaches</p>
              <p className='regPar' style={{ marginLeft: '5%' }}>
                Our trainers are local college athletes who know what it takes to compete at the highest levels of the sport. With years of experience playing and training in competitive environments, they bring expert knowledge, advanced techniques, and a competitive edge to each session. Beyond their skills, they are deeply rooted in each player’s success and offer key insight and encouragement to players working toward their goals.
              </p>
              <Button onClick={meetCoaches} style={{ marginLeft: '5%', marginBottom: '15px', marginTop: '10px', backgroundColor: '#D1501F', }} variant='contained'>Check Out Our Staff</Button>
            </Grid>
          </>
        )}
      </Grid>
    </div>
  );
};

export default AboutUs;
