import React, { useState } from 'react';
import { Grid, Button, Typography, TextField, FormControlLabel, Checkbox, Card, CardContent } from '@mui/material';
import emailjs from 'emailjs-com';
import './aboutUs.css';

const Form = () => {
  // Form state to hold form data
  const [formData, setFormData] = useState({
    parentName: '',
    email: '',
    phoneNumber: '',
    playerName: '',
    playerAge: '',
    playerAgeGroup: '',
    currentTeam: '',
    trainingType: {
      individual: false,
      smallGroup: false,
      notSure: false,
    },
    trainingGoals: '',
    preferredDays: {
      weekdays: false,
      weekends: false,
      flexible: false,
    },
    sessionLength: '',
    additionalNotes: '',
  });

  const [selectedSlot, setSelectedSlot] = useState('Any Time');
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState(false);
  const [date, setDate] = useState(() => {
    const today = new Date();
    today.setDate(today.getDate() + 1); // Set date to tomorrow
    return today.toISOString().split('T')[0]; // Format as YYYY-MM-DD
  });

  const timeSlots = [
    'Any Time',
    '10:00 AM',
    '10:30 AM',
    '11:00 AM',
    '11:30 AM',
    '12:00 PM',
    '12:30 PM',
    '1:00 PM',
    '2:00 PM',
    '2:30 PM',
    '3:00 PM',
  ];

  const buttonStyle = {
    backgroundColor: '#D1501F',
    color: 'white',
    borderColor:'#192C4E',
    
  };

  const nonButtonStyle = {
    backgroundColor: '#f0f0f0',
    borderColor:'#192C4E',
    color:'#192C4E'
  };

  // Handle input change for form fields
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

 // Handle checkbox change (training type or preferred days)
const handleCheckboxChange = (e) => {
  const { name, checked } = e.target;

  setFormData((prevData) => ({
    ...prevData,
    preferredDays: {
      ...prevData.preferredDays,
      [name]: checked,
    },
  }));
};


  // Handle training type checkbox change
  const handleTrainingTypeChange = (e) => {
    const { name, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      trainingType: {
        ...prevData.trainingType,
        [name]: checked,
      },
    }));
  };

  // Handle the time slot selection
  const handleSlotSelect = (slot) => {
    setSelectedSlot(slot);
  };

  // Submit the form and send email using EmailJS
  const handleSubmit = (e) => {
    e.preventDefault();

    // Validate form data
    if (!formData.parentName || !formData.email || !formData.phoneNumber || !formData.playerName || !formData.playerAge || !formData.playerAgeGroup || !(formData.trainingType.individual || formData.trainingType.smallGroup || formData.trainingType.notSure)) {
      setError(true);
      return;
    }

    const trainingType = Object.keys(formData.trainingType)
      .filter((key) => formData.trainingType[key])
      .join(', ');

    const preferredDays = Object.keys(formData.preferredDays)
      .filter((key) => formData.preferredDays[key])
      .join(', ');

    emailjs
      .send(
        'service_z9t7961',
        'template_uhr4tl1',
        {
          from_name: formData.parentName,
          from_email: formData.email,
          phone_number: formData.phoneNumber,
          player_name: formData.playerName,
          player_age: formData.playerAge,
          player_age_group: formData.playerAgeGroup,
          current_team: formData.currentTeam,
          training_type: trainingType,
          training_goals: formData.trainingGoals,
          preferred_days: preferredDays,
          session_length: formData.sessionLength,
          time_slot: selectedSlot, // Add the selected time slot
          preferred_date: date, // Add the preferred date
          additional_notes: formData.additionalNotes,
        },
        'euqvDPBSn3Wy-Ma8A'
      )
      .then(
        (response) => {
          console.log('SUCCESS!', response.status, response.text);
          setSubmitted(true);
          setError(false);
          window.scrollTo(0, 0); // Scroll to the top after successful submission
          setFormData({
            parentName: '',
            email: '',
            phoneNumber: '',
            playerName: '',
            playerAge: '',
            playerAgeGroup: '',
            currentTeam: '',
            trainingType: {
              individual: false,
              smallGroup: false,
              notSure: false,
            },
            trainingGoals: '',
            preferredDays: {
              weekdays: false,
              weekends: false,
              flexible: false,
            },
            sessionLength: '',
            additionalNotes: '',
          }); // Reset the form
          setDate(''); // Reset the date field
        },
        (err) => {
          console.log('FAILED...', err);
          setError(true);
        }
      );
  };

  return (
    <div className='rest' style={{ padding: '1.25%', color: 'white' }}>
    <Card style={{ padding: '20px', margin: '20px auto', width:"80%", backgroundColor: 'white' }}>
      <CardContent>
        <Typography variant="h4" gutterBottom style={{width:'100%', textAlign:'center', fontFamily:'MyCustomFontSemi'}}>
          Training Interest Form
        </Typography>
        
        {/* Parent Name */}
        <TextField
          label="Parent Name"
          name="parentName"
          value={formData.parentName}
          onChange={handleInputChange}
          fullWidth
          required
          margin="normal"
        />

        {/* Email */}
        <TextField
          label="Email"
          name="email"
          value={formData.email}
          onChange={handleInputChange}
          fullWidth
          required
          margin="normal"
          type="email"
        />

        {/* Phone Number */}
        <TextField
          label="Phone Number"
          name="phoneNumber"
          value={formData.phoneNumber}
          onChange={handleInputChange}
          fullWidth
          required
          margin="normal"
        />

        {/* Player Name */}
        <TextField
          label="Player Name"
          name="playerName"
          value={formData.playerName}
          onChange={handleInputChange}
          fullWidth
          required
          margin="normal"
        />

        {/* Player Age */}
        <TextField
          label="Player Age"
          name="playerAge"
          value={formData.playerAge}
          onChange={handleInputChange}
          fullWidth
          required
          margin="normal"
          type="number"
        />

        {/* Player Age Group */}
        <TextField
          label="Player Age Group"
          name="playerAgeGroup"
          value={formData.playerAgeGroup}
          onChange={handleInputChange}
          fullWidth
          required
          margin="normal"
        />

        {/* Training Type */}
        <Typography variant="subtitle1" gutterBottom>
          Training Type
        </Typography>
        <FormControlLabel
          control={<Checkbox checked={formData.trainingType.individual} onChange={handleTrainingTypeChange} name="individual" />}
          label="Individual"
        />
        <FormControlLabel
          control={<Checkbox checked={formData.trainingType.smallGroup} onChange={handleTrainingTypeChange} name="smallGroup" />}
          label="Small Group"
        />
        <FormControlLabel
          control={<Checkbox checked={formData.trainingType.notSure} onChange={handleTrainingTypeChange} name="notSure" />}
          label="Not Sure"
        />

        {/* Preferred Days */}
        <Typography variant="subtitle1" gutterBottom>
          Preferred Days
        </Typography>
        <FormControlLabel
          control={<Checkbox checked={formData.preferredDays.weekdays} onChange={handleCheckboxChange} name="weekdays" />}
          label="Weekdays"
        />
        <FormControlLabel
          control={<Checkbox checked={formData.preferredDays.weekends} onChange={handleCheckboxChange} name="weekends" />}
          label="Weekends"
        />
        <FormControlLabel
          control={<Checkbox checked={formData.preferredDays.flexible} onChange={handleCheckboxChange} name="flexible" />}
          label="Flexible"
        />

        {/* Session Length */}
        <TextField
          label="Session Length"
          name="sessionLength"
          value={formData.sessionLength}
          onChange={handleInputChange}
          fullWidth
          required
          margin="normal"
        />
        

        <p style={{ fontFamily: 'MyCustomFontSemi', textAlign: 'center', marginBottom: '0px',marginTop: '5px', fontSize:'1.1rem' }}>Select a preferred time slot for us to contact you with more information!</p>

        {/* Preferred Date */}
        <TextField
          label="Preferred Date"
          type="date"
          value={date}
          onChange={(e) => setDate(e.target.value)}
          fullWidth
          margin="normal"
          InputLabelProps={{
            shrink: true,
            style: { fontFamily: 'Regular' },
          }}
          inputProps={{ style: { fontFamily: 'Regular' } }}
        />

      
        <div className="timeSlotContainer" style={{ fontFamily: 'Regular' }}>
        
          <Grid container spacing={2}>
            {timeSlots.map((slot, index) => (
              <Grid item xs={4} key={index}>
                <Button
                  variant="outlined"
                  fullWidth
                  onClick={() => handleSlotSelect(slot)}
                  style={selectedSlot === slot ? buttonStyle : nonButtonStyle}
                >
                  {slot}
                </Button>
              </Grid>
            ))}
          </Grid>
        </div>

        {/* Additional Notes */}
        <TextField
          label="Additional Notes"
          name="additionalNotes"
          value={formData.additionalNotes}
          onChange={handleInputChange}
          fullWidth
          multiline
          rows={4}
          margin="normal"
        />

        {/* Submit Button */}
        <Button variant="contained" style={{ backgroundColor: '#D1501F'}} onClick={handleSubmit} fullWidth>
          Submit
        </Button>
        
        {/* Display success or error message */}
        {submitted && !error && <Typography color="success.main" gutterBottom>Your form has been submitted successfully!</Typography>}
        {error && <Typography color="error.main" gutterBottom>There was an error with the submission. Please ensure you have filled out all fields.</Typography>}
      </CardContent>
    </Card>
    </div>
  );
};

export default Form;
