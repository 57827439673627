import React from 'react';
import './CenteredDiv.css'; // Ensure you have the CSS for styling
import cam from './avg2.png'; // Assuming cam.png is in the same directory

const CenteredDiv2 = () => {
  return (
    <div className="centered-container" >
      <img src={cam} alt="Camera" className="centered-image2" />
      <div className="centered-text">Each trainer is equipped and ready to help each player with “in-game training and feedback”. This is done through either in-depth film analysis or live game analysis.</div>
    </div>
  );
};

export default CenteredDiv2;
