import React from 'react';
import './index.css';
import Pitch from './Pitch';
import './ImageLoop.css'; // Ensure you have the CSS for styling
import CenteredDiv from './CenteredDiv';
import CenteredDiv2 from './centeredDiv2';
import Types from './Types';
import { Button } from '@mui/material';  // Import Material UI button
import { useNavigate } from 'react-router-dom'; // Import useNavigate for navigation

const Training = () => {
  const navigate = useNavigate(); // Initialize navigate hook

  const handleRegisterClick = () => {
    window.scrollTo(0, 0); // Scroll to the very top-left of the page
    navigate('/register'); // Navigate to /register
  };

  return (
    <div className="rest">
      <div className="homeContainer">
        <div className="centeredHeader">
          <p className="headerLabel" style={{ textAlign: 'center', marginBottom: '20px' }}>
            Welcome to Wheaton Legacy Soccer Academy, where our mission is clear: to inspire and train the next generation of soccer players of all ages to reach their goals and dreams on the field while guiding each player to build their LEGACY
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Button
                variant="contained"
                onClick={handleRegisterClick}
                style={{ marginTop: '30px', fontFamily: 'MyCustomFontSemi', width: '40%', fontSize: '.75em', backgroundColor: '#D1501F', color: '#fff' }}
              >
                Contact Us
              </Button>
            </div>
          </p>
        </div>
      </div>

      <div className="text-rectangle22">Why Choose Wheaton Legacy Soccer Academy?</div> {/* Grey rectangle */}
      <Pitch />

      <div className="text-rectangle2">Comprehensive Training Plans</div> {/* Grey rectangle */}
      <CenteredDiv />

      <Types />

      <div className="text-rectangle2">Film/Game Analysis</div> {/* Grey rectangle */}
      <CenteredDiv2 />

      {/* New Section at the Bottom */}
      <div style={{ textAlign: 'center', marginTop: '40px', marginBottom: '50px' }}>
        <p className="text-rectangle22">
          Interested in learning more? Fill out the training interest form and schedule an intro call!
        </p>
        <Button
          variant="contained"
          onClick={handleRegisterClick}
          style={{ marginTop: '10px', fontFamily: 'MyCustomFontSemi', fontSize: '1.2em', backgroundColor: '#D1501F', color: '#fff' }}
        >
          Contact Us
        </Button>
      </div>
    </div>
  );
};

export default Training;
